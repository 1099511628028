import React from "react";

import Layout from "../components/layout";
import PurpleRow from "../components/purpleRow";

import { Link } from "gatsby";
import MacBook from "../assets/images/MacBookPro-2.png";
import IPhone from "../assets/images/iphone-kid.png";
import VideoTmp from "../assets/images/video_prev.png";
import HowWeHelp from "../components/howWeHelp";

import SolKom from "../assets/images/solutions/kom.png";
import SolKom1 from "../assets/images/solutions/kom1.png";

import SolAbsence from "../assets/images/solutions/absence.png";
import SolAbsence1 from "../assets/images/solutions/absence1.png";

import SolPayment from "../assets/images/solutions/payment.png";
import SolPayment1 from "../assets/images/solutions/payment1.png";
import { Player, BigPlayButton } from "video-react";
import { shuffle } from "../helpers";
import "video-react/dist/video-react.css";
import "./video-react.css";
import { opinions } from "../data/opinions";

const SolutionsPage = () => {
  const opinion = shuffle(opinions)[0].node;
  return (
    <Layout title="Rozwiązania">
      <div className="wrapper">
        <div className="section section-presentation">
          <div className="container">
            <div className="row">
              <div className="functions-main-wrapper">
                <div className="col-md-6 functions-main-text">
                  <div>
                    <h2>
                      <span className="purple">kids</span>
                      <span className="rose">up</span> - wygodne i proste rozwiązanie
                      dla przedszkola i żłobka.
                    </h2>
                    <Link to={"/zamow"} style={{ display: "block" }}>
                      <button className="violet-btn btn join-btn ">
                        Wypróbuj bezpłatnie
                      </button>
                    </Link>
                  </div>
                </div>
                <div className="col-md-6 hidden-sm">
                  <img className="functions-iphone" src={IPhone} />
                  <img
                    className="functions-ipad "
                    src={MacBook}
                    style={{ left: "50px", height: "380px" }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="section functions functions-padding solutions  how-it-works">
        <div className="container">
          <div className="row">
            <h2>Zobacz jak działa kidsup</h2>
            <p className="hiw-teaser">
              kidsup to elektroniczny system zarządzania przedszkolem, który
              sprawi, że prowadzenie Twojej placówki będzie jeszcze wygodniejsze
              i efektywniejsze. Oszczędzaj swój czas w bezpieczny i mądry
              sposób.
            </p>
            <div className="video-wrapper">
              <Player
                src="https://med01.kidsup.pl/kidsup_movie.mp4"
                poster="https://med01.kidsup.pl/movie_prev.jpeg"
              >
                <BigPlayButton position="center" />
              </Player>
            </div>
          </div>
        </div>
      </div>

      <div className="container">
        <div className="row solution-wrapper  middle-flex">
          <div className="col-md-6 col-sm-12 functions-part functions-part-images hidden-sm  hidden-xs">
            <div className="functions-part-images-inner">
              <img
                src={SolKom}
                alt=""
                className="solution-single-image-left solution-single-image-bigger"
                style={{
                  top: "-60px",
                  right: "150px",
                }}
              />
              <img
                src={SolKom1}
                alt=""
                className="solution-single-image-right solution-single-image-right-smaller"
                style={{
                  top: "30px",
                  right: "70px",
                }}
              />
            </div>
          </div>
          <div className="col-md-6 col-sm-12 functions-part ">
            <p className="fn-title-b">Komunikuj się</p>
            <p className="fn-teaser-b">
              Komunikuj się z rodzicami dzięki tablicy informacyjnej i funkcji
              czatu. Zapewnij sprawny przepływ informacji i dostarcz rodzicom
              oraz wychowawcom wygodne narzędzie komunikacyjne..
            </p>
          </div>
        </div>
      </div>
      <div className=" grey-background">
        <div className="container">
          <div className=" row solution-wrapper   middle-flex">
            <div className="col-md-6 col-sm-12 functions-part ">
              <p className="fn-title-b">Monitoruj płatności</p>
              <p className="fn-teaser-b">
                Monitoruj i zarządzaj płatnościami i wysyłaj automatyczne
                powiadomienia. Udostępnij rodzicom wgląd do rozliczeń i historii
                opłat.
              </p>
            </div>
            <div className="col-md-6 col-sm-12 functions-part functions-part-images hidden-sm hidden-xs">
              <div className="functions-part-images-inner">
                <img
                  src={SolPayment}
                  alt=""
                  className="solution-single-image-left"
                  style={{
                    top: "-70px",
                    left: "30px",
                  }}
                />
                <img
                  src={SolPayment1}
                  alt=""
                  className="solution-single-image-right"
                  style={{
                    top: "-70px",
                    left: "240px",
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row solution-wrapper  middle-flex">
          <div className="col-md-6 col-sm-12 functions-part functions-part-images  hidden-sm  hidden-xs">
            <div className="functions-part-images-inner">
              <img
                src={SolAbsence}
                alt=""
                className="solution-single-image-left"
                style={{
                  top: "-70px",
                  right: "280px",
                }}
              />
              <img
                src={SolAbsence1}
                alt=""
                className="solution-single-image-right"
                style={{
                  top: "-70px",
                  right: "70px",
                }}
              />
            </div>
          </div>
          <div className="col-md-6 col-sm-12 functions-part ">
            <p className="fn-title-b">Rozliczaj nieobecności</p>
            <p className="fn-teaser-b">
              Zautomatyzuj zgłaszanie i rozliczanie nieobecności dzieci. Zrzuć
              obowiązek podliczania godzin obecności dzieci w miesiącu na barki
              systemu kidsup.
            </p>
          </div>
        </div>
      </div>

      <HowWeHelp
        image={opinion.image.childImageSharp.fluid.src}
        place={opinion.place}
        author={opinion.author}
      >
        {opinion.opinion}
      </HowWeHelp>
      <PurpleRow>
        Cokolwiek robisz,
        <br /> możesz to zrobić w kidsup
      </PurpleRow>
    </Layout>
  );
};

export default SolutionsPage;
